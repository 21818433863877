import MethodController from './method_controller'
import { Modal } from 'bootstrap'
export default class extends MethodController {

  clickNext(e) {
    let isValid = true
    isValid = this.validateFields(1)
    if (isValid) {
      this.executeNextTab("mytab-link-1p2", "mystep-1p2", "mytab-link-1")
      this.getSelected('current_tab', '1.2')
    } else {
      e.preventDefault()
    }
  }

  clickNextP2(e) {
    let isValid = true
    isValid = this.validateFields(1.2)
    if (isValid) {
      let preload = this.getElement('btn-modal-preload')
      if (preload) {
        preload.click()
      }
      this.executeNextTabP2("mytab-link-2", "mystep-2", "mytab-link-1p2")
      this.getSelected('current_tab', '2')
    } else {
      e.preventDefault()
    }
  }

  clickPrevP2(e) {
    this.executeNextTab("mytab-link-1", "mystep-1", "mytab-link-1p2")
    this.getSelected('current_tab', '1')
  }

  changeTitle(e) {
    let title = this.getElement("title")
    let summaryTitle = this.getElement("summary-title")
    summaryTitle.innerHTML = title.value
  }

  clickMember(e) {
    let summaryMember = this.getElement("summary-member")
    let value = e.target.value

    if (value == '1') {
      summaryMember.innerHTML = 'Yes'
    } else {
      summaryMember.innerHTML = 'No'
    }
  }

  changeFname(e) {
    let fname = this.getElement("first_name")
    let summaryFname = this.getElement("summary-fname")
    summaryFname.innerHTML = fname.value
  }

  changeLname(e) {
    let lname = this.getElement("last_name")
    let summaryLname = this.getElement("summary-lname")
    summaryLname.innerHTML = lname.value
  }

  changeWorkEnvironment(e) {
    let workenv = this.getElement("00N1r00000HHVAd")
    let workenvText = this.getElement("00N1r00000HHVAL")
    let workenvTextDiv = this.getElement("other-00N1r00000HHVAd")
    let summaryWorkenv = this.getElement("summary-workenv")
    let summaryWorkenvOtherDiv = this.getElement("summary-workenvother-div")

    summaryWorkenv.innerHTML = workenv.value
    if (workenv.value == 'Other') {
      workenvText.disabled = false
      workenvTextDiv.classList.add('d-flex')
      workenvTextDiv.classList.remove('d-none')
      summaryWorkenvOtherDiv.classList.remove('d-none')
      summaryWorkenvOtherDiv.classList.add('d-flex')
    } else {
      summaryWorkenvOtherDiv.classList.add('d-none')
      summaryWorkenvOtherDiv.classList.remove('d-flex')
      if (this.getElement("00N1r00000HHVAd").value == 'University: student') {
        alert('If you are registering for self-study use, please download the User Guide from the euroqol.org homepage')
      }
      workenvText.disabled = true
      workenvTextDiv.classList.remove('d-flex')
      workenvTextDiv.classList.add('d-none')
    }
    if (workenv.value == "Consulting / CRO / Vendor" || workenv.value == "Industry / Pharmaceutical / Medical Device" || workenv.value == "Health Care Communications" || workenv.value == "Other") {
      this.getSelected("workenv_qualify", "n")
    } else {
      this.getSelected("workenv_qualify", "y")
    }
  }

  changeWorkEnvironmentOther(e) {
    let workEnvironmentOther = this.getElement("00N1r00000HHVAL")
    let summaryWorkEnvironmentOther = this.getElement("summary-workenvother")
    summaryWorkEnvironmentOther.innerHTML = workEnvironmentOther.value
  }

  changeCompany(e) {
    let company = this.getElement("company")
    let summaryCompany = this.getElement("summary-company")
    summaryCompany.innerHTML = company.value
  }

  changeEmail(e) {
    let url = '/verify_email'
    let email = this.getElement("email")
    if (email.value != '') {
      if (this.emailIsValid(email.value)) {
        if (this.getElement("prev_email").value != email.value) {
          this.hideElement("email_check")
          this.hideElement("email_x")
          this.hideElement("email_question")
          this.showElement("email_load")
          this.showElement("email_load_text")
          this.hideElement("required-email")
          this.hideElement("required-email-valid")
          const params = { email: email.value }
          this.getEmail(url, params)
            .then(data => {
              this.verifyEmail(data)
            });
        }
      }
    }
  }

  changePhone(e) {
    let url = '/valid_contacts'
    let phone = this.getElement("mobile").value.replace(/\s|\\|\W|[a-zA-Z_]/g, '')
    let country = this.getElement("country").value
    let summaryPhone = this.getElement("summary-phone")
    if (phone.length <= 15 && phone.length >= 8) {
      summaryPhone.innerHTML = phone
      this.getElement("mobile").value = phone
      if (country !== "") {
        this.hideElement("phone_check")
        this.hideElement("phone_x")
        this.showElement("phone_load")
        this.showElement("phone_load_text")
        const params = { country: country, phone: phone }
        this.getPhone(url, params)
        .then(data => {
          this.verifyPhone(data)
          this.useEmailCode(phone)
        });
      } else {
        alert("Please select your country where your mobile phone number is register.")
        phone.focus()
      }
    } else {
      alert("Please enter a valid phone number")
      phone.focus()
    }
  }

  changeTelephone(e) {
    let summaryPhone = this.getElement("summary-telephone")
    summaryPhone.innerHTML = phone.value
  }

  changeStreet(e) {
    let street = this.getElement("street")
    let summaryStreet = this.getElement("summary-street")
    summaryStreet.innerHTML = street.value
  }

  changeZip(e) {
    let zip = this.getElement("zip")
    let summaryZip = this.getElement("summary-zip")
    summaryZip.innerHTML = zip.value
  }

  changeCity(e) {
    let city = this.getElement("city")
    let summaryCity = this.getElement("summary-city")
    summaryCity.innerHTML = city.value
  }

  changeCountry(e) {
    let country = this.getElement("country")
    let phone = this.getElement('mobile').value
    let summaryCountry = this.getElement("summary-country")
    summaryCountry.innerHTML = country.value
    if (phone !== "") {
      this.changePhone()
    }
  }

  verifyEmail(data) {
    let summaryEmail = this.getElement("summary-email")
    let email = this.getElement("email")

    if (data.deliverable == true) {
      this.hideElement("email_load")
      this.hideElement("email_load_text")
      this.showElement("email_check")
      this.getSelected("email_status", "good")
      summaryEmail.innerHTML = email.value + '<svg xmlns="http://www.w3.org/2000/svg"  height="20" weight="30" class="eqText-success" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" /></svg>' + 'verified'
    } else if (data.deliverable == false) {
      this.hideElement("email_load")
      this.hideElement("email_load_text")
      this.showElement("email_x")
      this.getSelected("email_status", "bad")
      alert("Can you please check the spelling of your email address? The email checker was not able to verify your email address. This could mean that we cannot reach you. Please press the blue Ok button on the screen below to return to the page to enter your email address.")
      summaryEmail.innerHTML = email.value + ' <i class="text-danger fas fa-times-circle"></i>'
    } else {
      this.hideElement("email_load")
      this.hideElement("email_load_text")
      this.showElement("email_question")
      this.getSelected("email_status", "unknown")
      alert("The email checker was not able to check if you entered a valid email address. Please double check the spelling of your email address. If your email address is spelled incorrectly we cannot reach you. Please press the orange Back button on the screen below to return to the page ‘Your details’ to check the spelling of your email address")
      summaryEmail.innerHTML = email.value + ' <i class="text-warning fas fa-question-circle"></i>'
    }
    this.getSelected("prev_email", email.value)
  }

  loadEmailData(data) {
    this.getElement("title").value = data[0].title
    this.getElement("first_name").value = data[0].first_name
    this.getElement("last_name").value = data[0].last_name
    this.getElement("country").value = data[0].country
    this.getElement("phone").value = data[0].phone
    this.getElement("city").value = data[0].city
    this.getElement("street").value = data[0].street
    this.getElement("zip").value = data[0].zip

    this.getElement("summary-title").value = data[0].title
    this.getElement("summary-fname").value = data[0].first_name
    this.getElement("summary-lname").value = data[0].last_name
    this.getElement("summary-country").value = data[0].country
    this.getElement("summary-telephone").value = data[0].phone
    this.getElement("summary-city").value = data[0].city
    this.getElement("summary-street").value = data[0].street
    this.getElement("summary-zip").value = data[0].zip
  }

  async getEmail(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin', // include, *same-origin, omit
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }

  verifyPhone(data) {
    data ? this.validNumber() : this.invalidNumber()
  }

  useEmailCode(number) {
    const digits10 = ["1111111111", "1234567890"]
    if (digits10.includes(number)){
      this.getElement("00NQu000005MFs0").value = 1
      this.validNumber()
      this.hideElement("phone_x")
    } else {
      this.getElement("00NQu000005MFs0").value = 0
    }
  }

  validNumber() {
    this.hideElement("phone_load")
    this.hideElement("phone_load_text")
    this.showElement("phone_check")
    this.getElement("00NQu000005MFrz").value = 0
  }

  invalidNumber() {
    this.showElement("phone_x")
    this.hideElement("phone_load")
    this.hideElement("phone_load_text")
    this.getElement("00NQu000005MFrz").value = 1
  }

  addVendorDiv(e) {
    let get_id = e.currentTarget.id
    let result = get_id.split('-')
    let count = parseInt(result[1]) + 1
    let div = this.getElement("addVendorModal")
    let closestVendorEmail = this.getElement(get_id).closest(".addVendorParent").querySelector(".addVendorEmail").value.trim()
    let closestVendorPhone = this.getElement(get_id).closest(".addVendorParent").querySelector(".addVendorPhone").value.trim()
    if (this.emailIsValid(closestVendorEmail) && closestVendorPhone != "") {
      let htmlString = '<div class="addVendorParent" id="addedDivVendor' + count + '"  data-number="' + count + '">'
      htmlString += '<div class="row vendor-content col-sm-12 col-md-12 ml-3">'
      htmlString += '<div class="form-group pl-0 pr-1 col-sm-5 col-md-5">'
      htmlString += '<input type="text" id="addVendorEmail-' + count + '" class="form-control mt-1 addVendorEmail" name="vendorEmail[]">'
      htmlString += '</div>'
      htmlString += '<div class="form-group pl-1 pr-0 col-sm-5 col-md-5">'
      htmlString += '<input type="text" id="addVendorPhone-' + count + '" class="form-control mt-1 addVendorPhone" name="vendorPhone[]">'
      htmlString += '</div>'
      htmlString += '<div class="col-sm-2 col-md-2 px-0">'
      htmlString += '<span class="removeVendor" id="removeVendor-' + count + '" data-action="click->first#removeVendorDiv"><svg xmlns="http://www.w3.org/2000/svg" class="text-danger" height="20" fill="none" viewBox="-2.5 -2.5 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span>'
      htmlString += '<span class="addVendor mr-1" id="plusVendor-' + count + '" data-action="click->first#addVendorDiv"><svg xmlns="http://www.w3.org/2000/svg" class="eqText-success" height="20" fill="none" viewBox="-2.5 -2.5 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span>'
      htmlString += '</div>'
      htmlString += '</div>'
      htmlString += '</div>'
      div.insertAdjacentHTML('beforeend', htmlString);
      const remove_btn = this.getElement('removeVendor-' + parseInt(result[1]) + '')
      if (remove_btn) {
        this.hideElement(remove_btn.id)
      }
      this.hideElement(get_id)
    } else {
      alert('Please Input Correct Email or Phone')
    }
  }

  removeVendorDiv(e) {
    this.removeDivVendor(e, 'removeVendor-', 'plusVendor-', 'addedDivVendor')
  }

  updatetestVendor() {
    let textVendor = this.getElement("testVendor")
    let sumTextVendors = this.getElement("summary-vendors")
    let textVendorValue = ''
    let divs = document.querySelectorAll('.addVendorParent')
    for (let i = 0; i < divs.length; i++) {
      let closestVendorEmail = this.getElement("addVendorEmail-" + i).value.trim()
      let closestVendorPhone = this.getElement("addVendorPhone-" + i).value.trim()
      if (this.emailIsValid(closestVendorEmail) && closestVendorPhone != "") {
        textVendorValue += `${closestVendorEmail} (${closestVendorPhone})\n`
      }
    }
    textVendor.value = textVendorValue.trim()
    sumTextVendors.innerHTML = textVendorValue.trim()
  }

  removeDivVendor(e, removeId, addId, addDivId) {
    let get_id = e.currentTarget.id
    const get_count = get_id.split('-')
    let result = parseInt(get_count[1]) - 1
    const remove_div = this.getElement('' + addDivId + get_count[1] + '')
    const remove_btn = this.getElement('' + removeId + '' + result + '')
    if (remove_btn) {
      this.showElement(remove_btn.id)
    }
    this.showElement('' + addId + result + '')
    remove_div.remove()
  }

  saveVendor(e) {
    this.updatetestVendor()
    this.getElement('modalClose').click()
  }

  async getPhone(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin', // include, *same-origin, omit
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }

  executeNextTab(id, stepid, currentTab) {
    let find_tab = this.getElement(id)
    this.getElement(stepid).classList.add('active')
    find_tab.classList.remove('disabled')
    find_tab.classList.add('text-bold')
    this.getElement(currentTab).classList.remove('text-bold')

    find_tab.click()
  }

  executeNextTabP2(id, stepid, currentTab) {
    let regType = this.getElement("regtype")
    let eq5dUsed = this.getElement("00N1r00000HHVA8")
    let eq5dUsedDiv = this.getElement("div-00N1r00000HHVA8")
    let study = this.getElement("00N1r00000HHVA4")
    let studyDiv = this.getElement("div-00N1r00000HHVA4")
    let summaryeq5dUsedDiv = this.getElement("summary-eq5dused-div")
    let summaryStudyDiv = this.getElement("summary-study-div")

    let appTrue = this.getElement("00N1r00000KXPSh_1")
    let appFalse = this.getElement("00N1r00000KXPSh_0")
    let appDiv = this.getElement("div-00N1r00000KXPSh")
    let appDiv2 = this.getElement("div-00N1r00000KXPSh-2")
    let summaryAppDiv = this.getElement("summary-app-div")

    let summaryldh = this.getElement("summary-ld-header")
    let summaryldd = this.getElement("summary-ld-divs")
    let summarybdh = this.getElement("summary-bd-header")
    let summarybdd = this.getElement("summary-bd-divs")

    this.getElement(stepid).classList.add('active')
    this.getElement(id).classList.remove('disabled')
    this.getElement(currentTab).classList.remove('text-bold')
    this.getElement(id).classList.add('text-bold')

    this.getElement(id).click()
    if (regType.value == '2') {
      eq5dUsed.disabled = false
      eq5dUsedDiv.classList.add('d-flex')
      eq5dUsedDiv.classList.remove('d-none')
      study.disabled = false
      studyDiv.classList.add('d-flex')
      studyDiv.classList.remove('d-none')
      summaryeq5dUsedDiv.classList.remove('d-none')
      summaryStudyDiv.classList.remove('d-none')
      summaryeq5dUsedDiv.classList.add('d-flex')
      summaryStudyDiv.classList.add('d-flex')

      appTrue.disabled = false
      appFalse.disabled = false
      appDiv.classList.add('d-flex')
      appDiv.classList.remove('d-none')
      summaryAppDiv.classList.remove('d-none')
      summaryAppDiv.classList.add('d-flex')

      summaryldh.classList.add('d-none')
      summaryldd.classList.add('d-none')
      summarybdh.classList.add('d-none')
      summarybdd.classList.add('d-none')
    } else {
      eq5dUsed.disabled = true
      eq5dUsedDiv.classList.remove('d-flex')
      eq5dUsedDiv.classList.add('d-none')
      study.disabled = true
      studyDiv.classList.remove('d-flex')
      studyDiv.classList.add('d-none')
      summaryeq5dUsedDiv.classList.add('d-none')
      summaryStudyDiv.classList.add('d-none')
      summaryeq5dUsedDiv.classList.remove('d-flex')
      summaryStudyDiv.classList.remove('d-flex')

      appTrue.disabled = true
      appFalse.disabled = true
      appDiv.classList.add('d-flex')
      appDiv2.classList.add('d-none')
      summaryAppDiv.classList.add('d-none')
      summaryAppDiv.classList.remove('d-flex')

      summaryldh.classList.remove('d-none')
      summaryldd.classList.remove('d-none')
      summarybdh.classList.remove('d-none')
      summarybdd.classList.remove('d-none')
    }
  }
}
